import React from "react";
import CodersaidfundImage from "../images/codersaidfundtransptext.png"; // Image to be placed at the top
import CollageImage from "../images/collagentransptext.png"; // Image for the left side (Updated)
import "./CodersAidFund.css";

const CodersAidFund = () => {
  return (
    <div className="codersaidfund-container">
      <img
        src={CodersaidfundImage}
        alt="Coders Aid Fund"
        className="codersaidfund-image"
      />
      <div className="codersaidfund-content">
        <img
          src={CollageImage} // Use the new image here
          alt="Collage Homepage"
          className="codersaidfund-image-left"
        />
        <div className="codersaidfund-text-block">
          <h2>Banana kisses and pear hugs</h2>
          <p>
            <a
              href="https://www.codersaidfund.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Coders Aid Fund
            </a>{" "}
            wants to spread banana kisses and pear hugs to all parts of the
            world. We will build a platform as a service on Solana blockchain
            where charities, its members can raise crypto funds to coding
            courses and certifications.
            <br />
            <br />
            We believe releasing an NFT project is an opportunity for us to have
            a fun and engaging way to build a community for our cause. It raises
            awareness and is a more sustainable way to engage with you.
            <br />
            <br />
            Using an NFT collection solves the chicken and egg question for the
            usage of the platform. You need early adopters to start network
            effects, but the path to incremental adoption is not often clear. We
            will onboard many thousands of bananas and pears enthusiasts that
            could become donors.
            <br />
            <br />
            Learn more about the team in Team page.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CodersAidFund;

// import React from "react";
// import { useNavigate } from "react-router-dom";
// import "./DonateButton.css";

// const DonateButton = () => {
//   const navigate = useNavigate();

//   const handleClick = () => {
//     navigate("/donate");
//   };

//   return (
//     <button className="green-button" onClick={handleClick}>
//       Donate
//     </button>
//   );
// };

// export default DonateButton;

import React from "react";
import { useNavigate } from "react-router-dom";
import "./DonateButton.css";

const DonateButton = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/donate");
  };

  return (
    <button className="green-button" onClick={handleClick}>
      Donate
    </button>
  );
};

export default DonateButton;

import React from "react";
import "./Donate.css";

const Donate = () => {
  return (
    <div className="donate-container">
      <h1>Support Our Mission</h1>
      <p>
        At Coders Aid Fund, your donations help us build and maintain the
        platform, empowering underprivileged individuals by providing access to
        coding education and mentorship that leads to career opportunities.
        Every contribution, big or small, makes a difference in shaping the
        future of tech inclusivity.
      </p>

      {/* Company Donor Levels */}
      <div className="donor-section">
        <h2>Company Donor Levels</h2>
        <ul>
          <li>
            🥉 <strong>Bronze</strong> – $100 or more. Get your avatar or pic
            and active link to your website or X or LinkedIn.
          </li>
          <li>
            🥈 <strong>Silver</strong> – $250 or more. Get your avatar or pic
            and active link to your website or X or LinkedIn.
          </li>
          <li>
            🥇 <strong>Gold</strong> – $1,000 or more. Get your avatar or pic
            and active link to your website or X or LinkedIn.
          </li>
          <li>
            💎 <strong>Platinum</strong> – $5,000 or more. Get your avatar or
            pic and active link to your website or X or LinkedIn.
          </li>
        </ul>
      </div>

      {/* Individual Donor Levels */}
      <div className="donor-section">
        <h2>Individual Donor Levels</h2>
        <ul>
          <li>
            👥 <strong>Fellow</strong> – $25 or more. Get your personal active
            link to your website or X or LinkedIn
          </li>
          <li>
            🌟 <strong>Star</strong> – $100 or more. Get your avatar or pic and
            active link to your website or X or LinkedIn.
          </li>
          <li>
            👑 <strong>Kings & Queens</strong> – $500 or more. Get your avatar
            or pic and active link to your website or X or LinkedIn.
          </li>
          <li>
            🏆 <strong>Legends</strong> – $1,000 or more. Get your avatar or pic
            and active link to your website or X or LinkedIn.
          </li>
        </ul>
      </div>

      {/* Why Donate */}
      <div className="donor-section">
        <h2>Why Donate?</h2>
        <ul>
          <li>
            🚀 <strong>Empower Future Coders</strong> – Your donation supports
            scholarships, mentorship programs, and educational resources.
          </li>
          <li>
            🌍 <strong>Make an Impact</strong> – Help bridge the gap in tech
            education and create opportunities for those in need.
          </li>
          <li>
            🏅 <strong>Recognition</strong> – Donors will be recognized on our
            websites.
          </li>
        </ul>
        <p>
          Be a part of the change. Donate today and help us build a brighter
          future for aspiring developers worldwide!
        </p>
      </div>

      {/* Crypto Donations */}
      <div className="crypto-donation">
        <h2>Donate with Crypto</h2>
        <p>
          Support us by sending cryptocurrency to the following wallet
          addresses:
        </p>
        <ul>
          <li>
            <strong>Solana (SOL):</strong>{" "}
            <span className="wallet">
              JA1pugzMBfsGThqqnPgX8MkVC4wkSeCC8PKBuoaeWRcv
            </span>
          </li>
          <li>
            <strong>Ethereum (ETH):</strong>{" "}
            <span className="wallet">
              0xC38E6eB678e36a9188e94624312E1de537A404D4
            </span>
          </li>
          <li>
            <strong>Bitcoin (BTC):</strong>{" "}
            <span className="wallet">
              bc1qr4es33ejxcuzxy686uejeqnprnp2576gzk27a7
            </span>
          </li>
          <li>
            <strong>Tether (USDT):</strong>{" "}
            <span className="wallet">
              0xC38E6eB678e36a9188e94624312E1de537A404D4
            </span>
          </li>
          <li>
            <strong>USD Coin (USDC):</strong>{" "}
            <span className="wallet">
              0xC38E6eB678e36a9188e94624312E1de537A404D4
            </span>
          </li>
          <li>
            <strong>Binance Coin (BNB):</strong>{" "}
            <span className="wallet">
              0xC38E6eB678e36a9188e94624312E1de537A404D4
            </span>
          </li>
          <li>
            <strong>Binance USD (BUSD):</strong>{" "}
            <span className="wallet">
              0xC38E6eB678e36a9188e94624312E1de537A404D4
            </span>
          </li>
        </ul>
        <p>
          Submit your Transaction Hash (TxID) so we can validate your donation.
          The TxID is a unique identifier for the transaction on the blockchain,
          provided by your wallet or exchange.
        </p>
      </div>

      {/* Google Form Embed */}
      <div className="google-form">
        <h2>Permission to Feature on Donor Page</h2>
        <iframe
          title="Donation Form" // Added title for accessibility
          src="https://docs.google.com/forms/d/e/1FAIpQLSdxr07XWflur_ZoJyNqtdzijMR9CDqHLpSmbymBQAU4MqMDWw/viewform?embedded=true"
          width="640"
          height="938"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
        >
          Loading…
        </iframe>
      </div>
    </div>
  );
};

export default Donate;

// import React from "react";
// import "./Button.css";

// const Button = () => {
//   return <button className="caf-button">Taplink</button>;
// };

// export default Button;

import React from "react";
import "./Button.css";

const Button = () => {
  const openTaplink = () => {
    window.open(
      "https://taplink.cc/codersaidfund",
      "_blank",
      "noopener noreferrer"
    );
  };

  return (
    <button className="caf-button" onClick={openTaplink}>
      Taplink
    </button>
  );
};

export default Button;

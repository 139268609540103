import React from "react";
import NFTbackground1 from "../images/NFTbackground1.jpg"; // Import background image
import NFTvoiceBB from "../images/NFTvoiceBB.mp4";
import lovecompassiontransptext from "../images/bestestbestiestransptext.png"; // Import image for the text
// import WhitelistButton from "./WhitelistButton";
import "./Home.css";

const Home = () => {
  return (
    <div
      className="home-container"
      style={{ backgroundImage: `url(${NFTbackground1})` }} // Apply background image
    >
      {/* Image placed at the top of the container */}
      <img
        src={lovecompassiontransptext}
        alt="Love and Compassion"
        className="clipped-image"
      />

      {/* Main content container */}
      <div className="home-content">
        <video
          className="home-video"
          src={NFTvoiceBB}
          controls
          autoPlay
          muted
          loop
        />
        <div className="home-text-block">
          <h2>
            Quote from the jungle: “When life goes bananas, keep a pear close.”
          </h2>
          <br />
          <br />
          Welcome to The Bestest Besties Collection: Get ready to meet the most
          spirited duo in the NFT jungle: funky bananas and heart-throb pears!
          <br />
          <br />
          They’re quirky, they’re funky, and they’re here to make a difference.
          Each unique piece in this collection is a token of support for a
          greater cause.
          <br />
          <br />
          The Coders Aid Fund is our mission: raising funds to help people from
          all walks of life become coders and creators. Whether you’re a
          seasoned developer, a blockchain enthusiast, or just someone who loves
          cool art with a purpose, this collection has something for you.
          <br />
          <br />
          Why bananas and pears? Because life gets a little wild sometimes, and
          what better way to celebrate the chaos than with funky bananas that
          are here to remind you to stay playful, and heart-throb pears to keep
          your heart in the right place as you help empower the next wave of
          coders.
          <br />
          <br />
          Join the movement: By owning a piece of the Bestest Besties
          Collection, you’re not just adding a funky flair to your wallet—you’re
          joining a community of change-makers. Your contribution helps build
          opportunities, fund education, and shape futures, one coder at a time.
          <br />
          <br />
          Collect. Laugh. Code. Inspire.
          <br />
          <br />
          Step into the jungle, embrace the funk, and let’s make a difference
          together—with bananas, pears, and a whole lot of heart.
          <br />
          <br />
          {/* <h2>How to get on the whitelist?</h2>
          <br />
          Be active on our channels. Choose from 10 different whitelist NFTs. A
          requirement is to have joined minimum two of our social media channels
          and carry out the tasks listed below. Be active on all of our channels
          and we could email you access to two whitelisted NFTs.
          <br />
          <br />
          Twitter - Follow, retweet, like.
          <br />
          Telegram - Join group, invite friends, share link, participate in
          discussions.
          <br />
          Discord - Join server, introduce self, react to messages, invite
          friends.
          <br />
          LinkedIn - Follow, share posts, comment.
          <div className="whitelist-button-container">
            <WhitelistButton buttonText="Join the Whitelist" />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Home;

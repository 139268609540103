// import React from "react";
// import NFTbackground6 from "../images/NFTbackground6.jpg";
// import NFTpeople1 from "../images/NFTpeople1.jpg";
// import picJanSofta from "../images/picJanSofta.jpeg";
// import NFTpeople2 from "../images/NFTpeople2.jpg";
// import picPedro from "../images/picPedro.jpeg";
// import NFTpeople3 from "../images/NFTpeople3.jpg";
// import picCraig from "../images/picCraig.png";
// import NFTpeople4 from "../images/NFTpeople4.jpg";
// import picBeko from "../images/picBeko.jpeg";
// import NFTpeople5 from "../images/NFTpeople5.jpg";
// import picAlexandra from "../images/picAlexandra.jpeg";
// import "./PeopleInvolved.css";

// // Array of team members with their details
// const teamMembers = [
//   {
//     name: "Pear Hapilton aka Jan Softa",
//     initialImage: NFTpeople1,
//     hoverImage: picJanSofta,
//     linkedIn: "https://www.linkedin.com/in/jan-softa-680a79b2/",
//     description:
//       "Jan is CEO, fullstack developer with a passion for blockchain technology & DAPS. He runs the project, fundraise, code & all other bits & bobs.",
//   },
//   {
//     name: "Banana Boogieman aka Pedro Guimaraes",
//     initialImage: NFTpeople2,
//     hoverImage: picPedro,
//     linkedIn: "https://www.linkedin.com/in/pedroguimaraes/",
//     description:
//       "Pedro is a fullstack tech lead, bringing a creative touch to every project he works on. His focus is on ensuring intuitive user experiences, code quality from all coders.",
//   },
//   {
//     name: "Banana Splitson aka Craig D'Silva",
//     initialImage: NFTpeople3,
//     hoverImage: picCraig,
//     linkedIn: "https://www.linkedin.com/in/craig-s-dsilva/",
//     description:
//       "Craig is a passionate full-stack developer with a love for solving complex problems. He focuses on building scalable and efficient systems.",
//   },
//   {
//     name: "Banana Bre aka Abubakar Meigag",
//     initialImage: NFTpeople4,
//     hoverImage: picBeko,
//     linkedIn: "https://www.linkedin.com/in/abubakar-meigag/",
//     description:
//       "Abubakar is a fullstack devops/platform engineer expert with a positive attitude. He focuses on streamlining application development and deployment.",
//   },
//   {
//     name: "Pear Hawa hiita aka Alexandra Finch",
//     initialImage: NFTpeople5,
//     hoverImage: picAlexandra,
//     linkedIn: "https://www.linkedin.com/in/alexandra-finch-ena/",
//     description:
//       "Alexandra is a marketing strategist in branding and digital marketing. She fundraises by creating compelling stories for the NFT collection Bestest Besties.",
//   },
// ];

// // Truncate description to 250 characters and add ellipsis
// const truncateDescription = (text) => {
//   if (text.length > 250) {
//     return text.substring(0, 250) + "...";
//   }
//   return text;
// };

// // PeopleInvolved component
// const PeopleInvolved = () => {
//   return (
//     <div
//       className="peopleinvolved-container"
//       style={{ backgroundImage: `url(${NFTbackground6})` }}
//     >
//       <h1>Team</h1>
//       <div className="team-container">
//         {teamMembers.map((member, index) => (
//           <div className="team-member" key={index}>
//             <a
//               href={member.linkedIn}
//               target="_blank"
//               rel="noopener noreferrer"
//               className="image-link"
//             >
//               <div className="image-container">
//                 <img
//                   src={member.initialImage}
//                   alt={member.name}
//                   className="initial-image"
//                 />
//                 <img
//                   src={member.hoverImage}
//                   alt={member.name}
//                   className="hover-image"
//                 />
//               </div>
//             </a>
//             <a
//               href={member.linkedIn}
//               target="_blank"
//               rel="noopener noreferrer"
//               className="name-link"
//             >
//               {member.name}
//             </a>
//             {/* Updated description with truncation */}
//             <div className="member-description">
//               {truncateDescription(member.description)}
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default PeopleInvolved;

import React from "react";
import NFTbackground6 from "../images/NFTbackground6.jpg";
import NFTpeople1 from "../images/NFTpeople1.jpg";
import picJanSofta from "../images/picJanSofta.jpeg";
import NFTpeople2 from "../images/NFTpeople2.jpg";
import picPedro from "../images/picPedro.jpeg";
import NFTpeople3 from "../images/NFTpeople3.jpg";
import picCraig from "../images/picCraig.png";
import NFTpeople4 from "../images/NFTpeople4.jpg";
import picBeko from "../images/picBeko.jpeg";
import NFTpeople5 from "../images/NFTpeople5.jpg";
import picAlexandra from "../images/picAlexandra.jpeg";
// Import the image for the title
import teamTitleImage from "../images/teamtransptext.png"; // <-- Here
import "./PeopleInvolved.css";

// Array of team members with their details
const teamMembers = [
  {
    name: "Pear Hapilton aka Jan Softa",
    initialImage: NFTpeople1,
    hoverImage: picJanSofta,
    linkedIn: "https://www.linkedin.com/in/jan-softa-680a79b2/",
    description:
      "Jan is CEO, fullstack developer with a passion for blockchain technology & DAPS. He runs the project, fundraise, code & all other bits & bobs.",
  },
  {
    name: "Banana Boogieman aka Pedro Guimaraes",
    initialImage: NFTpeople2,
    hoverImage: picPedro,
    linkedIn: "https://www.linkedin.com/in/pedroguimaraes/",
    description:
      "Pedro is a fullstack tech lead, bringing a creative touch to every project he works on. His focus is on ensuring intuitive user experiences, code quality from all coders.",
  },
  {
    name: "Banana Splitson aka Craig D'Silva",
    initialImage: NFTpeople3,
    hoverImage: picCraig,
    linkedIn: "https://www.linkedin.com/in/craig-s-dsilva/",
    description:
      "Craig is a passionate full-stack developer with a love for solving complex problems. He focuses on building scalable and efficient systems.",
  },
  {
    name: "Banana Bre aka Abubakar Meigag",
    initialImage: NFTpeople4,
    hoverImage: picBeko,
    linkedIn: "https://www.linkedin.com/in/abubakar-meigag/",
    description:
      "Abubakar is a fullstack devops/platform engineer expert with a positive attitude. He focuses on streamlining application development and deployment.",
  },
  {
    name: "Pear Hawa hiita aka Alexandra Finch",
    initialImage: NFTpeople5,
    hoverImage: picAlexandra,
    linkedIn: "https://www.linkedin.com/in/alexandra-finch-ena/",
    description:
      "Alexandra is a marketing strategist in branding and digital marketing. She fundraises by creating compelling stories for the NFT collection Bestest Besties.",
  },
];

// Truncate description to 250 characters and add ellipsis
const truncateDescription = (text) => {
  if (text.length > 250) {
    return text.substring(0, 250) + "...";
  }
  return text;
};

// PeopleInvolved component
const PeopleInvolved = () => {
  return (
    <div
      className="peopleinvolved-container"
      style={{ backgroundImage: `url(${NFTbackground6})` }}
    >
      {/* Replaced h1 with the imported image */}
      <img
        src={teamTitleImage} // <-- Using the imported image here
        alt="Team"
        className="team-title-image"
      />
      <div className="team-container">
        {teamMembers.map((member, index) => (
          <div className="team-member" key={index}>
            <a
              href={member.linkedIn}
              target="_blank"
              rel="noopener noreferrer"
              className="image-link"
            >
              <div className="image-container">
                <img
                  src={member.initialImage}
                  alt={member.name}
                  className="initial-image"
                />
                <img
                  src={member.hoverImage}
                  alt={member.name}
                  className="hover-image"
                />
              </div>
            </a>
            <a
              href={member.linkedIn}
              target="_blank"
              rel="noopener noreferrer"
              className="name-link"
            >
              {member.name}
            </a>
            {/* Updated description with truncation */}
            <div className="member-description">
              {truncateDescription(member.description)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PeopleInvolved;

// import React from "react";
// import { Link } from "react-router-dom"; // Import Link
// import "./Footer.css";
// import logo from "../images/bestestbestieslogo.png"; // Import logo

// const Footer = () => {
//   return (
//     <footer>
//       <div className="footer-content">
//         <p>© 2024 The Bestest Besties Collection. All rights reserved.</p>
//         <p>
//           <Link to="/donate">Donate</Link> |{" "}
//           <Link to="/privacy-policy">Privacy Policy</Link> |{" "}
//           <Link to="/terms-of-use">TOU</Link> | <Link to="/faq">FAQ</Link> |{" "}
//           <a
//             href="https://docs.google.com/forms/d/e/1FAIpQLSe6s3xtoca6WypzDIF0BfXScRiUfNmJMmlHFJQcRmmtTvkumg/viewform?embedded=true"
//             target="_blank"
//             rel="noopener noreferrer"
//             className="contact-link"
//           >
//             Contact
//           </a>
//         </p>
//         <p>
//           <a
//             href="https://docs.google.com/forms/d/e/1FAIpQLSdNaGtCG79gs20VtP6fjhUp_ZoZNI6GWiYx-CONcshu-yvyOg/viewform"
//             target="_blank"
//             rel="noopener noreferrer"
//             className="newsletter-link"
//           >
//             Newsletter
//           </a>
//         </p>
//       </div>

//       {/* Logo container */}
//       <div className="footer-logo-container">
//         <img
//           src={logo}
//           alt="Bestest Besties Logo"
//           className="footer-logo-img"
//         />
//       </div>
//     </footer>
//   );
// };

// export default Footer;

// // import React, { useState } from "react";
// // import ReCAPTCHA from "react-google-recaptcha";
// // import "./Footer.css";

// // const Footer = () => {
// //   // State to handle reCAPTCHA validation
// //   const [captchaValue, setCaptchaValue] = useState(null);

// //   // Handle reCAPTCHA response
// //   const handleCaptchaChange = (value) => {
// //     setCaptchaValue(value); // This will store the reCAPTCHA response token
// //   };

// //   // Add a function to submit the form (if it's a form with a submit button)
// //   const handleSubmit = (e) => {
// //     e.preventDefault();
// //     if (!captchaValue) {
// //       alert("Please complete the reCAPTCHA.");
// //     } else {
// //       alert("Form submitted successfully!");
// //       // You can now handle form submission here (e.g., send data to the server)
// //     }
// //   };

// //   return (
// //     <footer className="footer">
// //       <div className="footer-links">
// //         <a href="/donate" className="footer-link">
// //           Donate
// //         </a>
// //         <a href="/privacy-policy" className="footer-link">
// //           Privacy Policy
// //         </a>
// //         <a href="/terms-of-use" className="footer-link">
// //           ToU
// //         </a>
// //         <a href="/faq" className="footer-link">
// //           FAQ
// //         </a>
// //         {/* Updated "Contact Us" link */}
// //         <a
// //           href="https://docs.google.com/forms/d/e/1FAIpQLSe6s3xtoca6WypzDIF0BfXScRiUfNmJMmlHFJQcRmmtTvkumg/viewform?embedded=true"
// //           target="_blank"
// //           rel="noopener noreferrer"
// //           className="footer-link"
// //         >
// //           Contact
// //         </a>
// //       </div>
// //       <p className="footer-text">
// //         © 2024 Coders Aid Fund. All rights reserved.
// //       </p>

// //       {/* Email collection section */}
// //       <div className="email-collection">
// //         <p className="email-text">
// //           <a
// //             href="https://docs.google.com/forms/d/e/1FAIpQLSdNaGtCG79gs20VtP6fjhUp_ZoZNI6GWiYx-CONcshu-yvyOg/viewform"
// //             target="_blank"
// //             rel="noopener noreferrer"
// //             className="email-link"
// //           >
// //             Sign up for our newsletter
// //           </a>
// //         </p>
// //       </div>

// //       {/* reCAPTCHA Component */}
// //       <div className="recaptcha-container">
// //         <ReCAPTCHA
// //           sitekey="your-site-key" // Replace with your actual site key
// //           onChange={handleCaptchaChange} // Captures reCAPTCHA response
// //         />
// //       </div>

// //       <form onSubmit={handleSubmit}>
// //         <button type="submit">Submit</button>
// //       </form>
// //     </footer>
// //   );
// // };

// // export default Footer;

// import React from "react";
// import "./Footer.css";

// const Footer = () => {
//   return (
//     <footer className="footer">
//       <div className="footer-links">
//         <a href="/donate" className="footer-link">
//           Donate
//         </a>
//         <a href="/privacy-policy" className="footer-link">
//           Privacy Policy
//         </a>
//         <a href="/terms-of-use" className="footer-link">
//           ToU
//         </a>
//         <a href="/faq" className="footer-link">
//           FAQ
//         </a>
//         {/* Updated "Contact Us" link */}
//         <a
//           href="https://docs.google.com/forms/d/e/1FAIpQLSe6s3xtoca6WypzDIF0BfXScRiUfNmJMmlHFJQcRmmtTvkumg/viewform?embedded=true"
//           target="_blank"
//           rel="noopener noreferrer"
//           className="footer-link"
//         >
//           Contact
//         </a>
//       </div>
//       <p className="footer-text">
//         © 2024 Coders Aid Fund. All rights reserved.
//       </p>

//       {/* Email collection section */}
//       <div className="email-collection">
//         <p className="email-text">
//           <a
//             href="https://docs.google.com/forms/d/e/1FAIpQLSdNaGtCG79gs20VtP6fjhUp_ZoZNI6GWiYx-CONcshu-yvyOg/viewform"
//             target="_blank"
//             rel="noopener noreferrer"
//             className="email-link"
//           >
//             Sign up for our newsletter
//           </a>
//         </p>
//       </div>
//     </footer>
//   );
// };

// export default Footer;

import React from "react";
import "./Footer.css";
import logo from "../images/bestestbestieslogo.png"; // Adjust path based on your file structure

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-links">
          <a href="/donate" className="footer-link">
            Donate
          </a>
          <a href="/privacy-policy" className="footer-link">
            Privacy Policy
          </a>
          <a href="/terms-of-use" className="footer-link">
            ToU
          </a>
          <a href="/faq" className="footer-link">
            FAQ
          </a>
          {/* Updated "Contact Us" link */}
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSe6s3xtoca6WypzDIF0BfXScRiUfNmJMmlHFJQcRmmtTvkumg/viewform?embedded=true"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link"
          >
            Contact
          </a>
        </div>
        <p className="footer-text">
          © 2024 Bestest Besties Collection. All rights reserved.
        </p>

        {/* Email collection section */}
        <div className="email-collection">
          <p className="email-text">
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSdNaGtCG79gs20VtP6fjhUp_ZoZNI6GWiYx-CONcshu-yvyOg/viewform"
              target="_blank"
              rel="noopener noreferrer"
              className="email-link"
            >
              Sign up for our newsletter
            </a>
          </p>
        </div>
      </div>

      {/* Adding the logo to the far right */}
      <div className="footer-logo">
        <img
          src={logo}
          alt="Coders Aid Fund Logo"
          className="footer-logo-img"
        />
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";
import loveCompassionImage from "../images/lovecompassiontransptext.png"; // Image to replace h1
import NFTweb6 from "../images/NFTweb6.png"; // Image 1 to jump
import NFTweb12 from "../images/NFTweb12.png"; // Image 2 to jump
import NFTweb13 from "../images/NFTweb13.png"; // Image 3 to jump
import "./NFTProject.css";

const NFTproject = () => {
  return (
    <div className="NFTproject-container">
      {/* Replace the h1 with the image */}
      <img
        src={loveCompassionImage}
        alt="Love, Compassion and Code"
        className="NFTproject-header-image"
      />

      <div className="NFTproject-content">
        <div className="NFTproject-jumping-images">
          <div className="jumping-text-container">
            <img
              src={NFTweb6}
              alt="NFT Web 6"
              className="NFTproject-jumping-image nft-6"
            />
            <p className="jumping-text nft-6-text">
              Mamas & papas! Thanks for supporting coders growth.
            </p>
          </div>
          <div className="jumping-text-container">
            <img
              src={NFTweb12}
              alt="NFT Web 12"
              className="NFTproject-jumping-image nft-12"
            />
            <p className="jumping-text nft-12-text">
              You sweeties! You highlight dynamic transformation.
            </p>
          </div>
          <div className="jumping-text-container">
            <img
              src={NFTweb13}
              alt="NFT Web 13"
              className="NFTproject-jumping-image nft-13"
            />
            <p className="jumping-text nft-13-text">
              Game changers! You are funky helping the digital evolution.
            </p>
          </div>
        </div>

        <div className="NFTproject-text-block">
          <h2>The Bestest Besties collection is for loving people</h2>
          <p>
            We are a pair of pears and a bunch of bananas that believe in the
            power of people to change their own lives given the chance.
            <br />
            <br />
            We do this by training refugees, financially disadvantaged people,
            and people from underrepresented groups to become coders so they can
            support themselves.
            <br />
            <br />
            The Bestest Besties collection is released to raise funds for our
            project Coders Aid Fund. The team will build a platform where
            charities and social entrepreneurs can register, create a profile,
            and raise crypto funds for trainees to take courses and
            certifications.
            <br />
            <br />
            The trainees are validated on the blockchain by a charity or social
            entrepreneur, and it helps build trust for donating to these groups.
            <br />
            <br />
            All charities, social entrepreneurs, and each of their validated
            members also get a donation link you can donate to.
            <br />
            <br />
            We use dynamic NFTs that change depending on how the trainees
            progress in the courses, which enables you to follow their progress.
            <br />
            <br />
            The holders of an NFT will have voting power on future features
            built. This first generation in the Bestest Besties Collection being
            sold, each NFT has 10 votes. Later, we will release a second
            generation with 6 votes and a third generation with 3 votes. Non-NFT
            holders have 1 vote.
            <br />
            <br />
            These bananas and pears want Coders Aid Fund to benefit from the
            raise but also help charities that educate coding skills by donating
            directly from the fundraiser to them.
            <br />
            <br />
            From the first raise, we donate $10,000 each to the coding boot
            camps featured on our website as supporters.
            <br />
            <br />
            The price for our funky bananas and heartthrob pears is listed at
            $100 each.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NFTproject;

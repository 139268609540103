// import React from "react";
// import NFTbackground5 from "../images/NFTbackground5.jpg";
// import Partner3Image from "../images/Partner3.png";
// import Partner5Image from "../images/Partner5.png";
// import Partner6Image from "../images/Partner6.jpg";
// import DonateButton from "../components/DonateButton";
// import PartnerLogo3 from "../images/logosomerco.png";
// import PartnerLogo4 from "../images/logoenagrowth.jpeg";
// import IndividualLogo1 from "../images/picJanSofta.jpeg";
// import IndividualLogo2 from "../images/picAlexandra.jpeg";

// import "./Partners.css";

// const partners = [
//   {
//     name: "Code Your Future",
//     description:
//       "CodeYourFuture gives its support, a UK-based non-profit organisation that trains some of the most deprived members of society to gain the tech skills they need to succeed. After the sale of our first-generation NFT collection Bestest Besties, we will donate $10,000 to this charity.",
//     image: Partner3Image,
//     website: "https://codeyourfuture.io/",
//   },
//   {
//     name: "Hack Your Future",
//     description:
//       "Hackyourfuture are helping new talent into the tech world by training people with a refugee background to become web developers and software testers through a free seven month program. After the sale of our first-generation NFT collection Bestest Besties, we will donate $10,000 to this charity.",
//     image: Partner6Image,
//     website: "https://www.hackyourfuture.net/",
//   },
//   {
//     name: "ILAPI",
//     description:
//       "ILAPI is a non-profit, non-partisan, public policy, and educational research organization operating exclusively for education, social development, business consultancy, and decentralization in Ghana. After the sale of our first-generation NFT collection Bestest Besties, we will donate $10,000 to this charity.",
//     image: Partner5Image,
//     website: "https://ilapi.org/",
//   },
// ];

// const Partners = () => {
//   return (
//     <div
//       className="partners-container"
//       style={{ backgroundImage: `url(${NFTbackground5})` }}
//     >
//       <h1>Supporters</h1>
//       {/* Donate Button */}
//       <div className="donate-button-container">
//         <DonateButton text="Donate Now" link="/donate" />
//       </div>
//       <h2>Coding bootcamps</h2>
//       <div className="partners-content">
//         {partners.map((partner, index) => (
//           <a
//             key={index}
//             href={partner.website}
//             target="_blank"
//             rel="noopener noreferrer"
//             className="partner-link"
//           >
//             <div className="partner">
//               <img
//                 src={partner.image}
//                 alt={partner.name}
//                 className="partner-image"
//               />
//               <div className="partner-text">
//                 <h2>{partner.name}</h2>
//                 <p>{partner.description}</p>
//               </div>
//             </div>
//           </a>
//         ))}
//       </div>
//       {/* Companies Section */}
//       <h2 className="partners-subtitle">Companies</h2>
//       <p className="partners-text">
//         We deeply appreciate the support of these companies in helping us build
//         a more inclusive and innovative tech industry.
//       </p>
//       {/* Donate Button */}
//       <div className="donate-button-container">
//         <DonateButton text="Donate Now" link="/donate" />
//       </div>
//       {/* Platinum Partners */}
//       <h3 className="partner-category">Platinum</h3>
//       <div className="partners-list row">
//         <div className="partner-item">
//           <a
//             href="https://www.somerco.com"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             <img
//               src={PartnerLogo3}
//               alt="Platinum"
//               className="partner-logo large"
//             />
//           </a>
//           <p className="partner-description">
//             Somerco has been a key supporter in our journey, providing valuable
//             resources and collaboration opportunities.
//           </p>
//         </div>
//       </div>
//       {/*
//       {/* Gold Partners */}
//       <h3 className="partner-category">Gold</h3>
//       <div className="partners-list row">
//         <div className="partner-item">
//           <a
//             href="https://www.enagrowth.com"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             <img src={PartnerLogo4} alt="Gold" className="partner-logo large" />
//           </a>
//           <p className="partner-description">
//             Enagrowth has supported our mission through sponsorship and advice,
//             making a huge impact on our progress.
//           </p>
//         </div>
//       </div>
//       {/* Silver Partners */}
//       <h3 className="partner-category">Silver</h3>
//       <div className="partners-list row">
//         <div className="partner-item">
//           <a
//             href="https://www.somerco.com"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             <img
//               src={PartnerLogo3}
//               alt="Silver"
//               className="partner-logo small"
//             />
//           </a>
//           <p className="partner-description">
//             Somerco has been a key supporter in our journey, providing valuable
//             resources and collaboration opportunities.
//           </p>
//         </div>
//       </div>
//       {/* Bronze Partners */}
//       <h3 className="partner-category">Bronze</h3>
//       <div className="partners-list row">
//         <div className="partner-item">
//           <a
//             href="https://www.somerco.com"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             <img
//               src={PartnerLogo3}
//               alt="Bronze"
//               className="partner-logo mini"
//             />
//           </a>
//           <p className="partner-description">
//             Bronze Supporter has been with us through their generosity, helping
//             us meet crucial milestones.
//           </p>
//         </div>
//       </div>
//       {/* Donate Button */}
//       <div className="donate-button-container">
//         <DonateButton text="Donate Now" link="/donate" />
//       </div>
//       {/* Individuals Section */}
//       <h2 className="partners-subtitle">Individuals</h2>
//       <p className="partners-text">
//         Our sincere love for the support to these individuals for helping us
//         build a more inclusive and innovative tech industry.
//       </p>
//       {/* Legends Partners */}
//       <h3 className="partner-category">Legends</h3>
//       <div className="partners-list row">
//         <div className="partner-item">
//           <a
//             href="https://www.linkedin.com/in/jan-softa-680a79b2"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             <img
//               src={IndividualLogo1}
//               alt="Legend Partner"
//               className="partner-logo large"
//             />
//           </a>
//           <p className="partner-description">Jan Softa</p>
//         </div>
//       </div>

//       {/* Kings and Queens Partners */}
//       <h3 className="partner-category">Kings and Queens</h3>
//       <div className="partners-list row">
//         <div className="partner-item">
//           <a
//             href="https://www.linkedin.com/in/jan-softa-680a79b2"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             <img
//               src={IndividualLogo1}
//               alt="Kings and Queens Partner"
//               className="partner-logo medium"
//             />
//           </a>
//           <p className="partner-description">Jan Softa</p>
//         </div>

//         <div className="partner-item">
//           <a
//             href="https://www.linkedin.com/in/alexandra-finch-ena"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             <img
//               src={IndividualLogo2}
//               alt="Kings and Queens Partner"
//               className="partner-logo medium"
//             />
//           </a>
//           <p className="partner-description">Alexandra Finch</p>
//         </div>
//       </div>
//       {/* Star Partners */}
//       <h3 className="partner-category">Stars</h3>
//       <div className="partners-list row">
//         <div className="partner-item">
//           <a
//             href="https://www.linkedin.com/in/jan-softa-680a79b2"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             <img
//               src={IndividualLogo1}
//               alt="Star Partner"
//               className="partner-logo small"
//             />
//           </a>
//           <p className="partner-description">Jan Softa</p>
//         </div>
//       </div>
//       {/* Fellow Partners */}

//       <h3 className="partner-category-2">Fellows</h3>
//       <div className="partners-list row">
//         <div className="partner-item">
//           <a
//             href="https://www.linkedin.com/in/jan-softa-680a79b2/"
//             target="_blank"
//             rel="noopener noreferrer"
//             className="partner-link-2"
//           >
//             Jan Softa
//           </a>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Partners;

import React from "react";
import StarryBackground from "./StarryBackground"; // Import the new StarryBackground component
import Partner3Image from "../images/Partner3.png";
import Partner5Image from "../images/Partner5.png";
import Partner6Image from "../images/Partner6.jpg";
import DonateButton from "../components/DonateButton";
import PartnerLogo3 from "../images/logosomerco.png";
import PartnerLogo4 from "../images/logoenagrowth.jpeg";
import IndividualLogo1 from "../images/picJanSofta.jpeg";
import IndividualLogo2 from "../images/picAlexandra.jpeg";
import SupportersTextImage from "../images/supporterstransptext.png";
import "./Partners.css";

const partners = [
  {
    name: "Code Your Future",
    description:
      "CodeYourFuture gives its support, a UK-based non-profit organisation that trains some of the most deprived members of society to gain the tech skills they need to succeed. After the sale of our first-generation NFT collection Bestest Besties, we will donate $10,000 to this charity.",
    image: Partner3Image,
    website: "https://codeyourfuture.io/",
  },
  {
    name: "Hack Your Future",
    description:
      "Hackyourfuture are helping new talent into the tech world by training people with a refugee background to become web developers and software testers through a free seven month program. After the sale of our first-generation NFT collection Bestest Besties, we will donate $10,000 to this charity.",
    image: Partner6Image,
    website: "https://www.hackyourfuture.net/",
  },
  {
    name: "ILAPI",
    description:
      "ILAPI is a non-profit, non-partisan, public policy, and educational research organization operating exclusively for education, social development, business consultancy, and decentralization in Ghana. After the sale of our first-generation NFT collection Bestest Besties, we will donate $10,000 to this charity.",
    image: Partner5Image,
    website: "https://ilapi.org/",
  },
];

const Partners = () => {
  return (
    <div className="partners-container">
      <StarryBackground /> {/* Add the StarryBackground component */}
      {/* Replace the h1 with the image */}
      <img
        src={SupportersTextImage}
        alt="Supporters"
        className="supporters-title-image"
      />
      {/* Donate Button */}
      <div className="donate-button-container">
        <DonateButton text="Donate Now" link="/donate" />
      </div>
      <br />
      <h2>Coding bootcamps</h2>
      <br />
      <div className="partners-content">
        {partners.map((partner, index) => (
          <a
            key={index}
            href={partner.website}
            target="_blank"
            rel="noopener noreferrer"
            className="partner-link"
          >
            <div className="partner">
              <img
                src={partner.image}
                alt={partner.name}
                className="partner-image"
              />
              <div className="partner-text">
                <h2>{partner.name}</h2>
                <p>{partner.description}</p>
              </div>
            </div>
          </a>
        ))}
      </div>
      {/* Companies Section */}
      <br />
      <h2 className="partners-subtitle">Companies</h2>
      <br />
      <p className="partners-text">
        We deeply appreciate the support of these companies in helping us build
        a more inclusive and innovative tech industry.
      </p>
      <br />
      {/* Donate Button */}
      <div className="donate-button-container">
        <DonateButton text="Donate Now" link="/donate" />
      </div>
      <br />
      {/* Platinum Partners */}
      <h3 className="partner-category">Platinum</h3>
      <div className="partners-list row">
        <div className="partner-item">
          <a
            href="https://www.somerco.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={PartnerLogo3}
              alt="Platinum"
              className="partner-logo large"
            />
          </a>
          <p className="partner-description">
            Somerco has been a key supporter in our journey, providing valuable
            resources and collaboration opportunities.
          </p>
        </div>
      </div>
      {/* Gold Partners */}
      <h3 className="partner-category">Gold</h3>
      <div className="partners-list row">
        <div className="partner-item">
          <a
            href="https://www.enagrowth.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={PartnerLogo4} alt="Gold" className="partner-logo large" />
          </a>
          <p className="partner-description">
            Enagrowth has supported our mission through sponsorship and advice,
            making a huge impact on our progress.
          </p>
        </div>
      </div>
      {/* Silver Partners */}
      <h3 className="partner-category">Silver</h3>
      <div className="partners-list row">
        <div className="partner-item">
          <a
            href="https://www.somerco.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={PartnerLogo3}
              alt="Silver"
              className="partner-logo small"
            />
          </a>
          <p className="partner-description">
            Somerco has been a key supporter in our journey, providing valuable
            resources and collaboration opportunities.
          </p>
        </div>
      </div>
      {/* Bronze Partners */}
      <h3 className="partner-category">Bronze</h3>
      <div className="partners-list row">
        <div className="partner-item">
          <a
            href="https://www.somerco.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={PartnerLogo3}
              alt="Bronze"
              className="partner-logo mini"
            />
          </a>
          <p className="partner-description">
            Bronze Supporter has been with us through their generosity, helping
            us meet crucial milestones.
          </p>
        </div>
      </div>
      {/* Donate Button */}
      <br />
      <div className="donate-button-container">
        <DonateButton text="Donate Now" link="/donate" />
      </div>
      {/* Individuals Section */}
      <br />
      <h2 className="partners-subtitle">Individuals</h2>
      <p className="partners-text">
        Our sincere love for the support to these individuals for helping us
        build a more inclusive and innovative tech industry.
      </p>
      {/* Legends Partners */}
      <h3 className="partner-category">Legends</h3>
      <div className="partners-list row">
        <div className="partner-item">
          <a
            href="https://www.linkedin.com/in/jan-softa-680a79b2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={IndividualLogo1}
              alt="Legend Partner"
              className="partner-logo large"
            />
          </a>
          <p className="partner-description">Jan Softa</p>
        </div>
      </div>
      {/* Kings and Queens Partners */}
      <h3 className="partner-category">Kings and Queens</h3>
      <div className="partners-list row">
        <div className="partner-item">
          <a
            href="https://www.linkedin.com/in/jan-softa-680a79b2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={IndividualLogo1}
              alt="Kings and Queens Partner"
              className="partner-logo medium"
            />
          </a>
          <p className="partner-description">Jan Softa</p>
        </div>

        <div className="partner-item">
          <a
            href="https://www.linkedin.com/in/alexandra-finch-ena"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={IndividualLogo2}
              alt="Kings and Queens Partner"
              className="partner-logo medium"
            />
          </a>
          <p className="partner-description">Alexandra Finch</p>
        </div>
      </div>
      {/* Star Partners */}
      <h3 className="partner-category">Stars</h3>
      <div className="partners-list row">
        <div className="partner-item">
          <a
            href="https://www.linkedin.com/in/jan-softa-680a79b2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={IndividualLogo1}
              alt="Star Partner"
              className="partner-logo small"
            />
          </a>
          <p className="partner-description">Jan Softa</p>
        </div>
      </div>
      <br />
      {/* Fellow Partners */}
      <h3 className="partner-category-2">Fellows</h3>
      <div className="partners-list row">
        <div className="partner-item">
          <a
            href="https://www.linkedin.com/in/jan-softa-680a79b2/"
            target="_blank"
            rel="noopener noreferrer"
            className="partner-link-2"
          >
            Jan Softa
          </a>
          <br />
        </div>
      </div>
    </div>
  );
};

export default Partners;

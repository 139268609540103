// import { useEffect } from "react";
// import { useLocation } from "react-router-dom";

// // Scroll to top component to handle scrolling behavior on route change
// const ScrollToTop = () => {
//   const location = useLocation();

//   useEffect(() => {
//     window.scrollTo(0, 0); // Scroll to the top when route changes
//   }, [location]);

//   return null;
// };

// export default ScrollToTop;

// ScrollToTop.js
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top whenever the location (route) changes
  }, [location]);

  return null;
};

export default ScrollToTop;

// import React, { useState } from "react";
// import "./News.css";

// const News = () => {
//   const newsArticles = [
//     {
//       title: 'Coders Aid Fund Launches "Bestest Besties" NFT Collection',
//       date: "February 19, 2025",
//       content: `The Coders Aid Fund has announced the launch of its new NFT collection, "Bestest Besties." This collection aims to... (Your article content here)`,
//       link: "[link to your news article or NFT collection]",
//       image: "[link to your image]",
//     },
//     {
//       title: "Breaking News: Tech Industry Faces Major Shift",
//       date: "February 18, 2025",
//       content: `The tech industry is undergoing significant changes. New players are entering the market, and existing companies are adapting their business strategies...`,
//       link: "[link to your news article or NFT collection]",
//       image: "[link to your image]",
//     },
//     {
//       title: "New Startups in the Blockchain Space",
//       date: "February 17, 2025",
//       content: `Several new startups are making waves in the blockchain space. These companies are developing innovative solutions that aim to revolutionize...`,
//       link: "[link to your news article or NFT collection]",
//       image: "[link to your image]",
//     },
//     {
//       title: "The Rise of AI in Digital Marketing",
//       date: "February 16, 2025",
//       content: `Artificial Intelligence is revolutionizing digital marketing. AI-powered tools are helping marketers personalize content, optimize ads...`,
//       link: "[link to your news article or NFT collection]",
//       image: "[link to your image]",
//     },
//     {
//       title: "Innovation in Renewable Energy Technologies",
//       date: "February 15, 2025",
//       content: `The latest advancements in renewable energy have opened new doors for clean energy solutions. Solar, wind, and hydrogen technologies are driving this change...`,
//       link: "[link to your news article or NFT collection]",
//       image: "[link to your image]",
//     },
//     {
//       title: "How NFTs Are Changing the Art World",
//       date: "February 14, 2025",
//       content: `NFTs are increasingly being used to authenticate and sell digital art. This technology is helping artists monetize their work in new ways...`,
//       link: "[link to your news article or NFT collection]",
//       image: "[link to your image]",
//     },
//     // Add more articles here as needed
//   ];

//   // State for tracking how many articles to show
//   const [visibleArticles, setVisibleArticles] = useState(3); // Initially show 3 articles

//   // Handle loading more articles
//   const loadMoreArticles = () => {
//     setVisibleArticles(visibleArticles + 3); // Show 3 more articles when "Load More" is clicked
//   };

//   // Extract 150 words from the content for preview
//   const getPreviewContent = (content) => {
//     const words = content.split(" ");
//     return words.slice(0, 150).join(" ") + (words.length > 150 ? "..." : "");
//   };

//   const shareOnSocialMedia = (platform, text, url) => {
//     const encodedText = encodeURIComponent(text);
//     const encodedUrl = encodeURIComponent(url);

//     let shareUrl;

//     switch (platform) {
//       case "twitter":
//         shareUrl = `https://twitter.com/intent/tweet?text=${encodedText}&url=${encodedUrl}`;
//         break;
//       case "discord":
//         shareUrl = `https://discord.com/channels?invitecode=<YOUR_DISCORD_INVITE_CODE>&content=${
//           encodedText + " - " + encodedUrl
//         }`;
//         break;
//       case "telegram":
//         shareUrl = `https://t.me/share/url?url=${encodedUrl}&text=${encodedText}`;
//         break;
//       case "linkedin":
//         shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=${encodedText}`;
//         break;
//       case "facebook":
//         shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
//         break;
//       case "reddit":
//         shareUrl = `https://reddit.com/submit?title=${encodedText}&url=${encodedUrl}`;
//         break;
//       default:
//         return;
//     }

//     if (shareUrl) {
//       window.open(shareUrl, "_blank", "noopener noreferrer");
//     }
//   };

//   return (
//     <div className="news-container">
//       <h1>News</h1>

//       {/* Newsletter Button */}
//       <a
//         href="https://docs.google.com/forms/d/e/1FAIpQLSdNaGtCG79gs20VtP6fjhUp_ZoZNI6GWiYx-CONcshu-yvyOg/viewform"
//         target="_blank"
//         rel="noopener noreferrer"
//         className="newsletter-btn"
//       >
//         Subscribe to our Newsletter
//       </a>

//       {/* Display articles based on visibleArticles */}
//       {newsArticles.slice(0, visibleArticles).map((article, index) => (
//         <div key={index} className="news-article">
//           <h2>{article.title}</h2>
//           <p className="news-date">{article.date}</p>
//           <p>{getPreviewContent(article.content)}</p>

//           {/* Read More Button */}
//           <a href={article.link} className="read-more-btn">
//             Read More
//           </a>

//           <div className="share-buttons">
//             <i
//               className="fab fa-twitter"
//               onClick={() =>
//                 shareOnSocialMedia("twitter", article.title, article.link)
//               }
//               aria-label="Share on Twitter"
//             ></i>
//             <i
//               className="fab fa-discord"
//               onClick={() =>
//                 shareOnSocialMedia("discord", article.title, article.link)
//               }
//               aria-label="Share on Discord"
//             ></i>
//             <i
//               className="fab fa-telegram"
//               onClick={() =>
//                 shareOnSocialMedia("telegram", article.title, article.link)
//               }
//               aria-label="Share on Telegram"
//             ></i>
//             <i
//               className="fab fa-linkedin"
//               onClick={() =>
//                 shareOnSocialMedia("linkedin", article.title, article.link)
//               }
//               aria-label="Share on LinkedIn"
//             ></i>
//             <i
//               className="fab fa-facebook"
//               onClick={() =>
//                 shareOnSocialMedia("facebook", article.title, article.link)
//               }
//               aria-label="Share on Facebook"
//             ></i>
//             <i
//               className="fab fa-reddit"
//               onClick={() =>
//                 shareOnSocialMedia("reddit", article.title, article.link)
//               }
//               aria-label="Share on Reddit"
//             ></i>
//           </div>
//         </div>
//       ))}

//       {/* Show "Load More" button if there are more articles to display */}
//       {visibleArticles < newsArticles.length && (
//         <button onClick={loadMoreArticles} className="load-more-btn">
//           Load More
//         </button>
//       )}
//     </div>
//   );
// };

// export default News;

import React, { useState } from "react";
import "./News.css";
import newsImage from "../images/newstransptext.png"; // Import image

const News = () => {
  const newsArticles = [
    {
      title: 'Coders Aid Fund Launches "Bestest Besties" NFT Collection',
      date: "February 19, 2025",
      content: `The Coders Aid Fund has announced the launch of its new NFT collection, "Bestest Besties." This collection aims to raise funds for charitable causes while providing unique digital art collectibles. The project focuses on supporting non-profit organizations working with children and education. Each NFT in the collection features vibrant artwork from up-and-coming artists, ensuring that every piece is one-of-a-kind.`,
      link: "https://link-to-article1.com",
      image: "https://link-to-image1.com",
    },
    {
      title: "Breaking News: Tech Industry Faces Major Shift",
      date: "February 18, 2025",
      content: `The tech industry is undergoing significant changes as new technologies continue to disrupt the market. Companies that once dominated are now struggling to keep up with emerging competitors who are leveraging artificial intelligence, blockchain, and new business models. This major shift is expected to reshape the landscape for years to come.`,
      link: "https://link-to-article2.com",
      image: "https://link-to-image2.com",
    },
    {
      title: "New Startups in the Blockchain Space",
      date: "February 17, 2025",
      content: `Several new startups are making waves in the blockchain space. These companies are developing innovative solutions that aim to revolutionize the way we interact with digital currencies and decentralized applications. From improving scalability to enhancing security, these startups are poised to make a significant impact on the industry.`,
      link: "https://link-to-article3.com",
      image: "https://link-to-image3.com",
    },
    {
      title: "The Rise of AI in Digital Marketing",
      date: "February 16, 2025",
      content: `Artificial Intelligence (AI) is increasingly being used in digital marketing strategies. AI-powered tools allow marketers to optimize campaigns, personalize content, and analyze consumer behavior with unprecedented accuracy. As AI continues to evolve, its role in digital marketing is expected to become even more integral.`,
      link: "https://link-to-article4.com",
      image: "https://link-to-image4.com",
    },
    {
      title: "Innovation in Renewable Energy Technologies",
      date: "February 15, 2025",
      content: `The latest advancements in renewable energy technologies are pushing the boundaries of what is possible in the quest for clean and sustainable energy. Solar, wind, and hydrogen technologies are being refined to increase efficiency and reduce costs. These innovations are expected to play a key role in combating climate change.`,
      link: "https://link-to-article5.com",
      image: "https://link-to-image5.com",
    },
    {
      title: "How NFTs Are Changing the Art World",
      date: "February 14, 2025",
      content: `Non-Fungible Tokens (NFTs) have created new opportunities for digital artists to monetize their work. By tokenizing artwork, artists can retain ownership and control over their creations, while collectors can securely buy, sell, and trade digital assets. This shift is revolutionizing the way art is bought and sold, and opening up new possibilities for artists worldwide.`,
      link: "https://link-to-article6.com",
      image: "https://link-to-image6.com",
    },
    {
      title: "5G Networks Are Here: What This Means for You",
      date: "February 13, 2025",
      content: `The rollout of 5G networks is underway, promising faster internet speeds, lower latency, and more reliable connections. This new technology is set to revolutionize industries ranging from healthcare to entertainment, enabling innovations like self-driving cars, smart cities, and more immersive AR/VR experiences.`,
      link: "https://link-to-article7.com",
      image: "https://link-to-image7.com",
    },
    {
      title: "The Future of Cryptocurrencies and Blockchain",
      date: "February 12, 2025",
      content: `Cryptocurrencies and blockchain technology are poised to continue shaping the future of finance, security, and data management. With increased institutional adoption and new regulatory frameworks, the future of these technologies looks bright, but questions about scalability and sustainability remain.`,
      link: "https://link-to-article8.com",
      image: "https://link-to-image8.com",
    },
  ];

  const [visibleArticles, setVisibleArticles] = useState(3);

  const loadMoreArticles = () => {
    setVisibleArticles(visibleArticles + 3);
  };

  const getPreviewContent = (content) => {
    const words = content.split(" ");
    return words.slice(0, 150).join(" ") + (words.length > 150 ? "..." : "");
  };

  const shareOnSocialMedia = (platform, text, url) => {
    const encodedText = encodeURIComponent(text);
    const encodedUrl = encodeURIComponent(url);

    let shareUrl;

    switch (platform) {
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?text=${encodedText}&url=${encodedUrl}`;
        break;
      case "discord":
        shareUrl = `https://discord.com/channels?invitecode=<YOUR_DISCORD_INVITE_CODE>&content=${
          encodedText + " - " + encodedUrl
        }`;
        break;
      case "telegram":
        shareUrl = `https://t.me/share/url?url=${encodedUrl}&text=${encodedText}`;
        break;
      case "linkedin":
        shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=${encodedText}`;
        break;
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
        break;
      case "reddit":
        shareUrl = `https://reddit.com/submit?title=${encodedText}&url=${encodedUrl}`;
        break;
      default:
        return;
    }

    if (shareUrl) {
      window.open(shareUrl, "_blank", "noopener noreferrer");
    }
  };

  return (
    <div className="news-container">
      {/* Replace h1 with the image */}
      <img src={newsImage} alt="News header" className="news-header-image" />

      {/* Newsletter Button */}
      <a
        href="https://docs.google.com/forms/d/e/1FAIpQLSdNaGtCG79gs20VtP6fjhUp_ZoZNI6GWiYx-CONcshu-yvyOg/viewform"
        target="_blank"
        rel="noopener noreferrer"
        className="newsletter-btn"
      >
        Subscribe to our Newsletter
      </a>

      {/* Display articles */}
      {newsArticles.slice(0, visibleArticles).map((article, index) => (
        <div key={index} className="news-article">
          <h2>{article.title}</h2>
          <p className="news-date">{article.date}</p>
          <p>{getPreviewContent(article.content)}</p>

          <a href={article.link} className="read-more-btn">
            Read More
          </a>

          <div className="share-buttons">
            <i
              className="fab fa-twitter"
              onClick={() =>
                shareOnSocialMedia("twitter", article.title, article.link)
              }
              aria-label="Share on Twitter"
            ></i>
            <i
              className="fab fa-discord"
              onClick={() =>
                shareOnSocialMedia("discord", article.title, article.link)
              }
              aria-label="Share on Discord"
            ></i>
            <i
              className="fab fa-telegram"
              onClick={() =>
                shareOnSocialMedia("telegram", article.title, article.link)
              }
              aria-label="Share on Telegram"
            ></i>
            <i
              className="fab fa-linkedin"
              onClick={() =>
                shareOnSocialMedia("linkedin", article.title, article.link)
              }
              aria-label="Share on LinkedIn"
            ></i>
            <i
              className="fab fa-facebook"
              onClick={() =>
                shareOnSocialMedia("facebook", article.title, article.link)
              }
              aria-label="Share on Facebook"
            ></i>
            <i
              className="fab fa-reddit"
              onClick={() =>
                shareOnSocialMedia("reddit", article.title, article.link)
              }
              aria-label="Share on Reddit"
            ></i>
          </div>
        </div>
      ))}

      {/* Show "Load More" button if there are more articles to display */}
      {visibleArticles < newsArticles.length && (
        <button onClick={loadMoreArticles} className="load-more-btn">
          Load More
        </button>
      )}
    </div>
  );
};

export default News;

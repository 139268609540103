import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p>
        Welcome to our Privacy Policy page! We value your privacy and strive to
        protect your personal information.
      </p>
      <h2>Information We Collect</h2>
      <p>
        When you visit our website, we may collect information such as your IP
        address, browser type, and browsing behavior. This information helps us
        enhance your experience and improve our services.
      </p>
      <h2>How We Use Your Information</h2>
      <br />
      <p>
        We use the information we collect to:
        <br />
        Improve website functionality and user experience.
        <br />
        Respond to your inquiries and provide customer support.
        <br />
        Send promotional materials, if you have opted in to receive them.
        <br />
      </p>
      <h2>Third-Party Services</h2>
      <p>
        We may use third-party services to analyze website traffic or provide
        additional functionality. These services may have their own privacy
        policies.
      </p>
      <h2>Your Consent</h2>
      <p>
        By using our website, you consent to our Privacy Policy and the terms
        outlined within.
      </p>
      <h2>Changes to This Policy</h2>
      <p>
        We reserve the right to update this policy at any time. Please review
        this page periodically for changes.
        <br />
        If you have any questions about our Privacy Policy, feel free to contact
        us.
        <br />
        We value our customers&#39; trust and commitment. Your data security is
        our priority.
        <br />
        It&#39;s important to review third-party policies to ensure you
        understand how your information is used.
      </p>
    </div>
  );
};

export default PrivacyPolicy;

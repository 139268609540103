import React from "react";
import "./TermsOfUse.css"; // Import the CSS file for styling

const TermsOfUse = () => {
  return (
    <div className="terms-container">
      <h1>Terms of Use</h1>
      <p>
        Welcome to the Coders Aid Fund website. Please read these terms of use
        carefully before using our website.
      </p>
      <div className="terms-section">
        <h2>1. Acceptance of Terms</h2>
        <p>
          The content on these websites, including but not limited to text,
          graphics, and logos, is the property of Somerco Research Ltd. and is
          protected by applicable copyright and trademark laws.
        </p>
      </div>
      <div className="terms-section">
        <h2>2. Use of the Website</h2>
        <p>
          You agree to use this website only for lawful purposes and in a way
          that does not infringe the rights of others or restrict or inhibit
          their use and enjoyment of the site.
        </p>
      </div>
      <div className="terms-section">
        <h2>3. Intellectual Property</h2>
        <p>
          The content on this website, including but not limited to text,
          graphics, and logos, is the property of Coders Aid Fund and is
          protected by applicable copyright and trademark laws.
        </p>
      </div>
      <div className="terms-section">
        <h2>4. Limitation of Liability</h2>
        <p>
          Somerco Research Ltd. will not be liable for any damages arising out
          of or in connection with the use of these websites.
        </p>
      </div>
      <div className="terms-section">
        <h2>5. Governance</h2>
        <p>
          These Terms of Use shall be governed by and construed in accordance
          with the laws of the United Kingdom. Any disputes arising from or
          related to the use of these websites will be subject to the exclusive
          jurisdiction of the courts of the United Kingdom.
        </p>
      </div>
      <div className="terms-section">
        <h2>6. Changes to These Terms</h2>
        <p>
          We reserve the right to modify these terms at any time. Your continued
          use of the website will signify your acceptance of any changes.
        </p>
      </div>
      <p className="last-updated">Last Updated: March 15, 2025</p>
    </div>
  );
};

export default TermsOfUse;

import React, { useEffect } from "react";

const StarryBackground = () => {
  useEffect(() => {
    const createStars = () => {
      const numStars = 100; // Number of stars you want to display
      const container = document.querySelector(".stars-container");

      for (let i = 0; i < numStars; i++) {
        const star = document.createElement("div");
        star.classList.add("star");
        star.style.top = `${Math.random() * 100}%`;
        star.style.left = `${Math.random() * 100}%`;
        star.style.animationDuration = `${Math.random() * 3 + 2}s`; // Random blinking duration
        container.appendChild(star);
      }
    };

    createStars();
  }, []);

  return <div className="stars-container"></div>;
};

export default StarryBackground;
